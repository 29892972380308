const colorSchemes = {
    primary: {
        //BlueGrey
        dark: {
            main: "#607d8b",
            one: "#eceff1",
            two: "#cfd8dc",
            three: "#b0bec5",
            four: "#90a4ae",
            five: "#78909c",
            six: "#607d8b",
            seven: "#546e7a",
            eight: "#455a64",
            nine: "#37474f",
            ten: "#263238",
        },
        //Inverted BlueGrey
        light: {
            main: "#607d8b",
            one: "#263238",
            two: "#37474f",
            three: "#455a64",
            four: "#546e7a",
            five: "#607d8b",
            six: "#78909c",
            seven: "#90a4ae",
            eight: "#b0bec5",
            nine: "#cfd8dc",
            ten: "#eceff1",
        }
    },
    secondary: {
        //Indigo
        dark: {
            main: "#3f51b5",
            one: "#e8eaf6",
            two: "#c5cae9",
            three: "#9fa8da",
            four: "#7986cb",
            five: "#5c6bc0",
            six: "#3f51b5",
            seven: "#3949ab",
            eight: "#303f9f",
            nine: "#283593",
            ten: "#1a237e",
        },
        //Inverted Indigo
        light: {
            main: "#3f51b5",
            one: "#1a237e",
            two: "#283593",
            three: "#303f9f",
            four: "#3949ab",
            five: "#3f51b5",
            six: "#5c6bc0",
            seven: "#7986cb",
            eight: "#9fa8da",
            nine: "#c5cae9",
            ten: "#e8eaf6",
        }
    },
    aqua: {
        dark: {
            main: "#0073BA",
            one: "#EBF3F7",
            two: "#C9E7F5",
            three: "#8BD3F7",
            four: "#48B8F0",
            five: "#1195D6",
            six: "#0073BA",
            seven: "#08548A",
            eight: "#0E3D66",
            nine: "#0C2B45",
            ten: "#0B1724",
        },
        light: {
            main: "#0073BA",
            one: "#0B1724",
            two: "#0C2B45",
            three: "#0E3D66",
            four: "#08548A",
            five: "#0073BA",
            six: "#1195D6",
            seven: "#48B8F0",
            eight: "#8BD3F7",
            nine: "#C9E7F5",
            ten: "#EBF3F7",
        }
    },
    default: {
        //Grey
        dark: {
            main: "#9e9e9e",
            one: "#fafafa",
            two: "#f5f5f5",
            three: "#eeeeee",
            four: "#e0e0e0",
            five: "#bdbdbd",
            six: "#9e9e9e",
            seven: "#757575",
            eight: "#616161",
            nine: "#424242",
            ten: "#212121",
        },
        //Inverted Grey
        light: {
            main: "#9e9e9e",
            one: "#212121",
            two: "#424242",
            three: "#616161",
            four: "#757575",
            five: "#9e9e9e",
            six: "#bdbdbd",
            seven: "#e0e0e0",
            eight: "#eeeeee",
            nine: "#f5f5f5",
            ten: "#fafafa",
        }
    }
};

export { colorSchemes }