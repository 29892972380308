import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard';
import { useLayoutEffect } from 'react';

function App() {
  useLockBodyScroll();
  
  return (
    <div className="App" style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0" }}>
      <Dashboard />
    </div>
  );
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
   // Get original body overflow
   const originalStyle = window.getComputedStyle(document.body).overflow;  
   // Prevent scrolling on mount
   document.body.style.overflow = 'hidden';
   // Re-enable scrolling when component unmounts
   return () => document.body.style.overflow = originalStyle;
   }, []); // Empty array ensures effect is only run on mount and unmount
}

export default App;
