import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';

import Utils from './Utils';

const utils = new Utils();

class Timer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            secondsCounter: 7200,
            hours: "02",
            minutes: "00",
            seconds: "00",
            lastTime: null,
            timing: false
        }
        this.startTimer = this.startTimer.bind(this)
        this.pauseTimer = this.pauseTimer.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
        this.emptyToZero = this.emptyToZero.bind(this)
        this.removeNonNumeric = this.removeNonNumeric.bind(this)
        this.changeHour = this.changeHour.bind(this)
        this.changeMinute = this.changeMinute.bind(this)
        this.changeSecond = this.changeSecond.bind(this)
    }

    startTimer() {
        this.setState({timing: true})
        var that = this
        this.timer = setInterval(() => {
            var currTime = Date.now()
            if (that.state.lastTime) {
                var diff = Math.round((currTime - that.state.lastTime)/1000)
            } else {
                diff = 1
            }

            var secondsCounter = that.state.secondsCounter - diff

            if (secondsCounter <= 0) {
                that.props.closeSnackbar("warning")
                that.props.openSnackbar("Time's up", "error", 30000)
                that.resetTimer()
                return
            }
            if (secondsCounter == 15) {
                that.props.openSnackbar(secondsCounter + " seconds remaining", "warning", 15000)
            } else if (secondsCounter < 15) {
                that.props.updateSnackbarMessage(secondsCounter + " second" + (secondsCounter == 1 ? "" : "s") + " remaining", "warning")
            }
            if (secondsCounter == 1800) {
                that.props.openSnackbar("30 minutes remaining", "info", 30000)
            } else if (secondsCounter == 900) {
                that.props.openSnackbar("15 minutes remaining", "warning", 30000)
            }
            var hours = (Math.floor(secondsCounter / 3600)).toString()
            var minutes = (Math.floor((secondsCounter % 3600) / 60)).toString()
            var seconds = Math.round((secondsCounter % 3600) % 60).toString()
            if (hours.length == 1) {
                hours = "0" + hours
            }
            if (minutes.length == 1) {
                minutes = "0" + minutes
            }
            if (seconds.length == 1) {
                seconds = "0" + seconds
            }
            that.setState({secondsCounter: secondsCounter, hours: hours, minutes: minutes, seconds: seconds, lastTime: currTime })
        }, 1000);
    }

    pauseTimer() {
        clearInterval(this.timer)
        this.setState({ lastTime: null, timing: false })
    }

    resetTimer() {
        var initialHours = "02"
        var initial = "00"
        clearInterval(this.timer)
        this.setState({ secondsCounter: 7200, hours: initialHours, minutes: initial, seconds: initial, lastTime: null, timing: false })
    }

    emptyToZero(value) {
        var digits = value.replace(/[^\d]/g, '')
        if (digits === "") {
            return 0
        }
        return value
    }

    removeNonNumeric(value) {
        return value.replace(/[^\d.]/g, '')
    }

    changeHour(value) {
        this.pauseTimer()
        value = this.removeNonNumeric(value)
        var secondsCounter = this.emptyToZero(value)*3600 + this.emptyToZero(this.state.minutes)*60 + this.emptyToZero(this.state.seconds)*1
        this.setState({ secondsCounter: secondsCounter, hours: value === "" ? " " : value })
    }

    changeMinute(value) {
        this.pauseTimer()
        value = this.removeNonNumeric(value)
        var secondsCounter = this.emptyToZero(this.state.hours)*3600 + this.emptyToZero(value)*60 + this.emptyToZero(this.state.seconds)*1
        this.setState({ secondsCounter: secondsCounter, minutes: value === "" ? " " : value })
    }

    changeSecond(value) {
        this.pauseTimer()
        value = this.removeNonNumeric(value)
        var secondsCounter = this.emptyToZero(this.state.hours)*3600 + this.emptyToZero(this.state.minutes)*60 + this.emptyToZero(value)*1
        this.setState({ secondsCounter: secondsCounter, seconds: value === "" ? " " : value })
    }

    render() {
        const styles = {
            container: {
                height: 35,
                width: 280,
            },
            item: {
                height: 35,
                backgroundColor: "rgba(" + utils.hexToRgba(this.props.colorScheme.eight, 0.5).join() + ")",
                borderRadius: 5,
            },
            iconButton: {
                color: this.props.colorScheme.two,
                width: 30,
            },
            timeText: {
                marginLeft: 15,
                marginRight: 15,
                color: this.props.colorScheme.two,
            },
            input: {
                width: 40,
                height: 30,
                color: this.props.colorScheme.two,
                backgroundColor: "transparent",
                fontSize: "default",
                fontWeight: "bold",
            },
        }

        return (
            <Grid container style={styles.container}>
                <Grid item xs={12} align="left" style={styles.item}>
                    {this.state.timing 
                        ?   <Tooltip title={"Pause timer"} enterDelay={1500}>
                                <Button style={styles.iconButton} onClick={() => this.pauseTimer()}>
                                    <PauseCircleOutlineIcon fontSize="default"/>
                                </Button>
                            </Tooltip>
                        :   <Tooltip title={"Start timer"} enterDelay={1500}>
                                <Button style={styles.iconButton} onClick={() => this.startTimer()}>
                                    <PlayCircleOutlineIcon fontSize="default"/>
                                </Button>
                            </Tooltip>
                    }
                    <Typography align="right" variant="caption" style={styles.timeText}>
                        <Tooltip title={"Click to enter hours"} enterDelay={250}>
                            <InputBase id="hours" style={styles.input} inputProps={{ style: {textAlign: 'center'} }} value={this.state.hours} onChange={(event) => this.changeHour(event.target.value)}/>
                        </Tooltip>
                        <Tooltip title={"Click to enter minutes"} enterDelay={250}>
                            <InputBase id="minutes"  pattern="[0-9.+-e]" style={styles.input} inputProps={{ style: {textAlign: 'center'} }} value={this.state.minutes} onChange={(event) => this.changeMinute(event.target.value)}/>
                        </Tooltip>
                        <Tooltip title={"Click to enter seconds"} enterDelay={250}>
                            <InputBase id="seconds" style={styles.input} inputProps={{ style: {textAlign: 'center'} }} value={this.state.seconds} onChange={(event) => this.changeSecond(event.target.value)}/>
                        </Tooltip>
                    </Typography>
                    <Tooltip title={"Reset timer"} enterDelay={1500}>
                        <Button style={styles.iconButton} onClick={() => this.resetTimer()}>
                            <RefreshIcon fontSize="default"/>
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }
}

export default Timer