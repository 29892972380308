import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import FlagIcon from '@material-ui/icons/Flag';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import Utils from './Utils';

const utils = new Utils();

class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            width: 0,
        }
    }

    componentDidMount() {
        this.setState({ width: this.props.width })
    }

    render() {
        const styles = {
            container: {
                height: "100%",
                minWidth: this.state.width,
            },
            headingGrid: {
                height: 65,
                paddingTop: 10,
                paddingLeft: 20,
                borderBottom: "solid",
                borderWidth: 1,
                borderColor: this.props.darkTheme ? "rgba(" + utils.hexToRgba(this.props.colorScheme.eight, 0.5).join() + ")" : this.props.colorScheme.seven,
            },
            heading: {
                height: "100%",
                color: this.props.colorScheme.two,
            },
            listGrid: {
                height: "100%",
            },
            listIcon: {
                color: this.props.colorScheme.two,
                shaded: {
                    color: "rgba(" + utils.hexToRgba(this.props.colorScheme.two, 0.5).join() + ")",
                }
            },
            listItem: {
                paddingLeft: 20,
            },
            nestedListItem: {
                paddingLeft: 40,
            },
            nestedListItem2: {
                paddingLeft: 60,
                color: "rgba(" + utils.hexToRgba(this.props.colorScheme.two, 0.5).join() + ")",
                fontSize: "small",
            },
            listItemText: {
                color: this.props.colorScheme.two,
                fontSize: "default",
            },
            nestedListItemText: {
                color: this.props.colorScheme.two,
                fontSize: "small",
            },
        }

        return (
            <Grid container style={styles.container}>
                <Grid item xs={12} style={styles.headingGrid}>
                    <Typography align="left" variant="body1" style={styles.heading}>
                        Imperial EE <br />
                        Past Papers
                    </Typography>
                </Grid>
                <Grid item xs={12} style={styles.listGrid}>
                    <List>
                        {this.props.data.map((yearGroup, ygIndex) =>
                            <Box key={ygIndex + " box"} boxShadow={yearGroup.expanded ? 0 : 0}>
                                <ListItem key={ygIndex} button style={styles.listItem} selected={yearGroup.expanded} onClick={() => this.props.toggleExpand(ygIndex.toString())}>
                                    {/* <ListItemIcon>
                                        {yearGroup.expanded ? <ExpandLess style={styles.listIcon} /> : <ExpandMore style={styles.listIcon} />}
                                    </ListItemIcon> */}
                                    <ListItemText disableTypography primary={<Typography style={styles.listItemText}>{yearGroup.name}</Typography>} />
                                    {yearGroup.expanded ? <ExpandLess style={styles.listIcon} /> : <ExpandMore style={styles.listIcon} />}
                                </ListItem>
                                <Collapse in={yearGroup.expanded} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {yearGroup.modules.map((module, mIndex) => 
                                        <Box key={ygIndex + "/" + mIndex + " box"} boxShadow={module.expanded ? 0 : 0}>
                                            <ListItem key={ygIndex + "/" + mIndex} button style={styles.nestedListItem} selected={module.expanded} onClick={() => this.props.toggleExpand(ygIndex + "/" + mIndex)}>
                                                {/* <ListItemIcon>
                                                    {module.expanded ? <ExpandLess fontSize="small" style={styles.listIcon} /> : <ExpandMore fontSize="small" style={styles.listIcon} />}
                                                </ListItemIcon> */}
                                                <ListItemText disableTypography primary={<Typography style={styles.nestedListItemText}>{module.name}</Typography>} />
                                                {/* {module.expanded ? <ExpandLess fontSize="small" style={styles.listIcon} /> : <ExpandMore fontSize="small" style={styles.listIcon} />} */}
                                            </ListItem>
                                            <Collapse in={module.expanded} timeout="auto" unmountOnExit>
                                                <List component="div">
                                                    {module.papers.map((paper, pIndex) => 
                                                        <ListItem key={ygIndex + "/" + mIndex + "/" + pIndex} button style={styles.nestedListItem2} onClick={() => this.props.selectPaper(ygIndex + "/" + mIndex + "/" + pIndex)}>
                                                            {/* <ListItemIcon>
                                                                <DescriptionIcon fontSize="small" style={styles.listIcon} />
                                                            </ListItemIcon> */}
                                                            <ListItemText disableTypography primary={<Typography style={styles.nestedListItemText}>{paper.name}</Typography>} />
                                                            {paper.flagged && <FlagIcon fontSize="small" style={styles.listIcon} />}
                                                            {paper.completed && <CheckIcon fontSize="small" style={styles.listIcon} />}
                                                            {paper.rating > 0 && <OfflineBoltIcon fontSize="small" style={styles.listIcon.shaded} />}
                                                            {paper.rating > 0 && paper.rating}
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Collapse>
                                        </Box>
                                    )}
                                </List>
                            </Collapse>
                        </Box>
                        )}
                    </List>
                </Grid>
            </Grid>
        )
    }
}

export default SideBar