import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
// import { Document, Page } from 'react-pdf';  

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import FlagIcon from '@material-ui/icons/Flag';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import CheckIcon from '@material-ui/icons/Check';

import Utils from './Utils';
import CommentBox from './CommentBox';

const utils = new Utils();

class Body extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paperSelected: true,
            solutionsSelected: false,
            pageNumber: 1,
            numPages: null,
            rating: null,
            ratingHovered: false,
            aggregateRating: null,
            comments: [
                {
                    pk: 8,
                    comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                    likes: 1,
                    date: "10-01-2021",
                    replying: false,
                    subcomments: [],
                },
                {
                    pk: 7,
                    comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                    likes: 1,
                    date: "15-10-2020",
                    replying: false,
                    subcomments: [],
                },
                {
                    pk: 6,
                    comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                    likes: 1,
                    date: "21-01-2020",
                    replying: false,
                    subcomments: [],
                },
                {
                    pk: 2,
                    comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                    likes: 5,
                    date: "14-12-2019",
                    replying: false,
                    subcomments: [
                        {
                            pk: 3,
                            comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                            parent_pk: 2,
                            likes: 10,
                            date: "15-12-2019",
                        },
                        {
                            pk: 4,
                            comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape). If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape). If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape). If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape). If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                            parent_pk: 2,
                            likes: 1,
                            date: "20-01-2020",
                        },
                        {
                            pk: 5,
                            comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                            parent_pk: 2,
                            likes: 3,
                            date: "20-01-2020",
                        },
                    ],
                },
                {
                    pk: 1,
                    comment: "If given, uses a negative margin to counteract the padding on one side (this is often helpful for aligning the left or right side of the icon with content above or below, without ruining the border size and shape).",
                    likes: 1,
                    date: "10-10-2019",
                    replying: false,
                    subcomments: [],
                },
            ]
        }
        this.getRating = this.getRating.bind(this)
        this.showReplyCommentBox = this.showReplyCommentBox.bind(this)
        this.updateLikeCount = this.updateLikeCount.bind(this)
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
        this.setRating = this.setRating.bind(this)
    }

    componentDidMount() {
        this.setState({ rating: this.props.selectedPaper.rating, aggregateRating: this.props.selectedPaper.rating })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedPaper.paper_url !== this.props.selectedPaper.paper_url) {
            document.getElementById("body").scrollTo(0, 0);
            this.setState({ paperSelected: true, solutionsSelected: false, rating: this.props.selectedPaper.rating, aggregateRating: this.props.selectedPaper.rating })
        }
    }

    setRating(rating) {
        if (rating > 0) {
            this.setState({ rating: rating, ratingHovered: true })
        } else {
            this.setState({ rating: this.state.aggregateRating, ratingHovered: false })
        }
    }

    getRating() {
        let rating = []
        for (var i = 0; i < this.props.selectedPaper.rating; i++) {
            rating.push(<OfflineBoltIcon key={i} fontSize="default"/>)
        }
        for (i = this.props.selectedPaper.rating; i < 5; i++) {
            rating.push(<OfflineBoltOutlinedIcon key={i} fontSize="default"/>)
        }
        return rating
    }

    showReplyCommentBox(pk, bool) {
        var comments = this.state.comments
        for (var i in comments) {
            if (comments[i].pk === pk) {
                comments[i].replying = bool
                break
            }
        }
        this.setState({ comments: comments })
    }

    updateLikeCount(pk, bool) {
        var comments = this.state.comments
        if (bool) {
            var update = 1
        } else {
            update = -1
        }
        for (var i in comments) {
            if (comments[i].pk === pk) {
                comments[i].likes = comments[i].likes + update
                break
            } else {
                for (var j in comments[i].subcomments){
                    if (comments[i].subcomments[j].pk === pk) {
                        comments[i].subcomments[j].likes = comments[i].subcomments[j].likes + update
                        break
                    }
                }
            }
        }
        this.setState({ comments: comments })
    }

    onDocumentLoadSuccess(numPages) {
        this.setState({ numPages: numPages })
    }

    render() {
        const bodyPadding = 30;
        const styles = {
            container: {
                height: "100%",
                color: this.props.colorScheme.two,
            },
            titleGrid: {
                height: "10%",
            },
            titleText: {
                color: this.props.colorScheme.two,
            },
            tabsGrid: {
                height: "10%",
            },
            spacingGrid: {
                five: {
                    height: "5%",
                },
                ten: {
                    height: "10%",
                },
                fifteen: {
                    height: "15%",
                },
                twenty: {
                    height: "20%",
                },
            },
            tabsText: {
                color: "rgba(" + utils.hexToRgba(this.props.colorScheme.two, 0.5).join() + ")",
            },
            tabsTextSelected: {
                color: this.props.colorScheme.two,
                textDecoration: "underline",
            },
            transparentButton: {
                color: this.props.colorScheme.two,
                backgroundColor: "rgba(0,0,0,0)",
            },
            sideBarTransparentButton: {
                color: this.props.colorScheme.two,
                backgroundColor: "rgba(0,0,0,0)",
                right: bodyPadding,
                position: "fixed",
            },
            button: {
                color: this.props.colorScheme.two,
                backgroundColor: this.props.colorScheme.nine,
            },
            sideBarButton: {
                divider: {
                    height: 20,
                    marginRight: 10,
                    marginLeft: 10,
                    backgroundColor: this.props.colorScheme.two,
                },
                button: {
                    color: this.props.colorScheme.two,
                    backgroundColor: this.props.colorScheme.nine,
                    right: bodyPadding,
                    position: "fixed",
                },
            },
            documentGrid: {
                height: "100%",
                backgroundColor: this.state.paperSelected ? this.props.colorScheme.nine : this.props.colorScheme.eight,
            },
            box: {
                height: "100%",
            },
            icon: {
                color: this.props.colorScheme.two,
            },
            commentsTitleGrid: {
                marginTop: 50,
                height: 100,
            },
            dividerGrid: {
                marginTop: "5%",
            },
            sideBarDividerGrid: {
                outer: {
                    height: "5%",
                },
                inner: {
                    padding: 30,
                    paddingRight: 0,
                    borderTop: "solid",
                    borderWidth: 1,
                    borderColor: this.props.darkTheme ? "rgba(" + utils.hexToRgba(this.props.colorScheme.eight, 0.5).join() + ")" : this.props.colorScheme.seven,
                    right: bodyPadding,
                    position: "fixed",
                    width: "12%",
                },
            },
            divider: {
                backgroundColor: this.props.darkTheme ? "rgba(" + utils.hexToRgba(this.props.colorScheme.eight, 0.5).join() + ")" : this.props.colorScheme.seven,
            },
            rating: {
                grid: {
                    right: bodyPadding,
                    position: "fixed",
                    height: "10%",
                },
                title: {
                    // padding: 30,
                },
                button: {
                    color: "rgba(" + utils.hexToRgba(this.props.colorScheme.four, this.state.ratingHovered ? 1 : 0.5).join() + ")",
                    backgroundColor: "transparent",
                    width: 35,
                }
            },
            paperFrame: {
                height: this.state.paperSelected && this.state.solutionsSelected ? "50%" : "100%",
                width: "100%",
                border: 0,
                allowfullscreen: "false",
                display: this.state.paperSelected ? "block" : "none",
                borderBottom: "solid",
                borderWidth: 4,
                borderColor: this.props.colorScheme.main,
            },
            solutionsFrame: {
                height: this.state.paperSelected && this.state.solutionsSelected ? "50%" : "100%",
                width: "100%",
                border: 0,
                allowfullscreen: "false",
                display: this.state.solutionsSelected ? "block" : "none",
            },
            subpage: {
                padding: bodyPadding,
                paddingRight: 0,
                height: "100%",
                width: "80%",
            },
            sideBar: {
                padding: bodyPadding,
                paddingLeft: 0,
                height: "100%",
                width: "20%",
            },
            sideBarItems: {
                height: "100%",
            },
            iconLink: {
                color: this.props.colorScheme.two,
            },
            commentSection: {
                marginBottom: 100,
            },
            commentBox: {
                newComment: {
                    marginBottom: 50,
                },
                comment: {
                    marginTop: 5,
                },
                subcomment: {
                    marginTop: -15,
                },
                newSubcomment: {
                    marginTop: 10,
                    marginBottom: 10,
                },
            },
        }

        return (
            this.props.selectedPaper.yearGroup &&
                <Grid container style={styles.container}>
                    <Grid container style={styles.subpage}>
                        <Grid item xs={12} align="left" style={styles.titleGrid}>
                            <Typography variant="h4" style={styles.titleText}>
                                {this.props.selectedPaper.yearGroup} {this.props.selectedPaper.module}: {this.props.selectedPaper.paper} {this.props.selectedPaper.flagged && <FlagIcon />} {this.props.selectedPaper.completed && <CheckIcon />}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="left" style={styles.tabsGrid}>
                            {this.props.selectedPaper.paper_url &&
                                <Button disableRipple disableFocusRipple style={styles.transparentButton} onClick={() => this.setState({ paperSelected: true, solutionsSelected: false })}>
                                    <Typography variant="button" style={this.state.paperSelected && !this.state.solutionsSelected ? styles.tabsTextSelected : styles.tabsText}>
                                        Paper
                                    </Typography>
                                </Button>
                            }
                            {this.props.selectedPaper.solutions_url &&
                                <Button disableRipple disableFocusRipple style={styles.transparentButton} onClick={() => this.setState({ paperSelected: false, solutionsSelected: true })}>
                                    <Typography variant="button" style={!this.state.paperSelected && this.state.solutionsSelected ? styles.tabsTextSelected : styles.tabsText}>
                                        Solutions
                                    </Typography>
                                </Button>
                            }
                            {this.props.selectedPaper.paper_url && this.props.selectedPaper.solutions_url &&
                                <Button disableRipple disableFocusRipple style={styles.transparentButton} onClick={() => this.setState({ paperSelected: true, solutionsSelected: true })}>
                                    <Typography variant="button" style={this.state.paperSelected && this.state.solutionsSelected ? styles.tabsTextSelected : styles.tabsText}>
                                        Merge
                                    </Typography>
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} align="left" style={styles.documentGrid}>
                            <Box boxShadow={5} style={styles.box}>
                                {/* <Document
                                    file={{
                                        url: "www.padkay.co.uk/EE1/EE1-01/2016.pdf"
                                    }}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    >
                                    <Page pageNumber={this.state.pageNumber} />
                                </Document>
                                <p>Page {this.state.pageNumber} of {this.state.numPages}</p> */}
                                {this.props.selectedPaper.paper_url && <iframe title={this.props.selectedPaper.name} src={this.props.selectedPaper.paper_url} style={styles.paperFrame} />}
                                {this.props.selectedPaper.solutions_url && <iframe title={this.props.selectedPaper.name + " Solutions"} src={this.props.selectedPaper.solutions_url} style={styles.solutionsFrame} />}
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={styles.dividerGrid}>
                            <Divider style={styles.divider} />
                        </Grid>
                        <Grid item xs={12} align="left" style={styles.commentsTitleGrid}>
                            <Typography variant="h5">
                                Discussion
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="left" style={styles.commentSection}>
                            <Grid item xs={12} style={styles.commentBox.newComment}>
                                <CommentBox 
                                    colorScheme={this.props.colorScheme} 
                                    darkTheme={this.props.darkTheme} 
                                    isNew={true}
                                    isSubcomment={false}
                                />
                            </Grid>
                            {this.state.comments.map((c, index) => 
                                <Grid container key={c.pk}>
                                    <Grid item xs={12} style={styles.commentBox.comment}>
                                        <CommentBox
                                            colorScheme={this.props.colorScheme}
                                            darkTheme={this.props.darkTheme}
                                            isNew={false}
                                            pk={c.pk}
                                            commentInfo={c}
                                            isSubcomment={false}
                                            updateLikeCount={this.updateLikeCount}
                                            showReplyCommentBox={this.showReplyCommentBox}/>
                                    </Grid>
                                    {c.replying &&
                                        <Grid id={`comment-reply-${c.pk}`} container style={styles.commentBox.newSubcomment}>
                                            <Grid item xs={1}>
                                                {/* spacing */}
                                            </Grid>
                                            <Grid item xs={11} style={styles.commentBox.subcomment}>
                                                <CommentBox
                                                    colorScheme={this.props.colorScheme}
                                                    darkTheme={this.props.darkTheme}
                                                    isNew={true}
                                                    pk={c.pk}
                                                    isSubcomment={true}
                                                    showReplyCommentBox={this.showReplyCommentBox}/>
                                            </Grid>
                                        </Grid>
                                    }
                                    {c.subcomments.map((c, index) => 
                                        <Grid container key={c.pk}>
                                            <Grid item xs={1}>
                                                {/* spacing */}
                                            </Grid>
                                            <Grid item xs={11} style={styles.commentBox.subcomment}>
                                                <CommentBox
                                                    colorScheme={this.props.colorScheme}
                                                    darkTheme={this.props.darkTheme}
                                                    isNew={false}
                                                    pk={c.pk}
                                                    commentInfo={c}
                                                    isSubcomment={true}
                                                    updateLikeCount={this.updateLikeCount}/>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    {/* SIDEBAR */}
                    <Grid container style={styles.sideBar}>
                        <Grid item xs={12} align="center" style={styles.sideBarItems}>
                            <Grid item xs={12} align="right" style={styles.titleGrid}>
                                <Button style={styles.sideBarButton.button} onClick={this.props.toggleCompleted}>
                                    {this.props.selectedPaper.completed ? <CheckIcon /> : "Mark As"} Completed
                                </Button>
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.titleGrid}>
                                <Button style={styles.sideBarButton.button} onClick={this.props.toggleFlagged}>
                                    {this.props.selectedPaper.flagged ? <FlagIcon /> : <FlagOutlinedIcon />}
                                </Button>
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.sideBarDividerGrid.outer}>
                                <Grid item xs={12} align="right" style={styles.sideBarDividerGrid.inner}>
                                    {/* <Divider style={styles.sideBarDivider} /> */}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.rating.grid}>
                                <Tooltip title={"Paper difficulty rating"} placement="left" enterDelay={500}>
                                    <Typography variant="caption" style={styles.rating.title}>
                                        Power Rating
                                        <br />
                                        {this.props.selectedPaper.userRating > 0 &&
                                            "You rated " + this.props.selectedPaper.userRating
                                        }
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.spacingGrid.five}>
                                {/* Spacing */}
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.rating.grid}>
                                {[1, 2, 3, 4, 5].map((c, index) => 
                                    <IconButton style={styles.rating.button} onClick={() => this.props.addUserRating(c)} onPointerEnter={() => this.setRating(c)} onPointerLeave={() => this.setRating(0)}>
                                        {this.state.rating > index ? <OfflineBoltIcon key={c} fontSize="default"/> : <OfflineBoltOutlinedIcon key={c} fontSize="default"/>}
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.spacingGrid.ten}>
                                {/* Spacing */}
                            </Grid>
                            <Grid item xs={12} align="right" style={styles.titleGrid}>
                                <a href={this.props.selectedPaper.paper_url} target="_blank" style={styles.iconLink}>
                                    <Button style={styles.sideBarButton.button}>
                                        Paper
                                        <Divider style={styles.sideBarButton.divider} orientation="vertical" />
                                        <LaunchIcon fontSize="default" />
                                    </Button>
                                </a>
                            </Grid>
                            {this.props.selectedPaper.solutions_url &&
                                <Grid item xs={12} align="right" style={styles.titleGrid}>
                                    <a href={this.props.selectedPaper.solutions_url} target="_blank" style={styles.iconLink}>
                                        <Button style={styles.sideBarButton.button}>
                                            Solutions 
                                            <Divider style={styles.sideBarButton.divider} orientation="vertical" />
                                            <LaunchIcon fontSize="default" />
                                        </Button>
                                    </a>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
        )
    }
}

export default Body