import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReplyIcon from '@material-ui/icons/Reply';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Utils from './Utils';

const utils = new Utils();

class CommentBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comment: "",
            liked: false,
        }
        this.handleLike = this.handleLike.bind(this)
    }

    componentDidMount() {
        var element = document.getElementById(`comment-reply-${this.props.pk}`)
        if (element !== null) {
            element.scrollIntoView({behavior: "smooth", block: "nearest"})
        }
    }

    handleLike() {
        this.props.updateLikeCount(this.props.commentInfo.pk, !this.state.liked)
        this.setState({ liked: !this.state.liked })
    }

    render() {
        const styles = {
            container: {
                minHeight: 50,
                padding: 10,
                paddingRight: 0,
            },
            iconGrid: {

            },
            avatar: {
                height: 43,
                marginTop: this.props.isNew ? 0 : 22,
                color: this.props.colorScheme.two,
            },
            icon: {
                height: 43,
                marginBottom: -10,
                color: this.props.colorScheme.two,
            },
            firstIconButton: {
                marginTop: -15,
                color: this.props.colorScheme.two,
                backgroundColor: "rgba(0,0,0,0)",
            },
            iconButton: {
                marginTop: -15,
                marginLeft: 10,
                color: this.props.colorScheme.two,
                backgroundColor: "rgba(0,0,0,0)",
            },
            commentBox: {
                
            },
            textPaper: {
                padding: this.props.isNew ? 5 : 0,
                backgroundColor: this.props.isNew ? this.props.colorScheme.nine : this.props.colorScheme.ten,
            },
            text: {
                width: "100%",
                paddingRight: 10,
                paddingLeft: this.props.isNew ? 10 : 0,
                color: "rgba(" + utils.hexToRgba(this.props.isNew ? this.props.colorScheme.one : this.props.colorScheme.two, this.props.darkTheme || this.props.isNew ? 0.5 : 0.8).join() + ")",
                backgroundColor: this.props.isNew ? this.props.colorScheme.nine : this.props.colorScheme.ten,
            },
            postTools: {
                grid: {
                    borderTop: "solid",
                    borderWidth: 1,
                    borderColor: this.props.colorScheme.eight,
                    paddingTop: 5,
                },
                button: {
                    color: this.props.colorScheme.two,
                    backgroundColor: this.props.colorScheme.nine,
                },
                iconButton: {
                    color: this.props.colorScheme.two,
                    backgroundColor: "rgba(0,0,0,0)",
                },
                icon: {
                    padding: 5,
                    color: this.props.colorScheme.two,
                },
            },
        }

        return (
            <Grid container style={styles.container}>
                <Grid item xs={1} align="center" style={styles.iconGrid}>
                    <AccountCircleIcon fontSize={this.props.isSubcomment ? "default" : "large"} style={styles.avatar} />
                </Grid>
                <Grid item xs={11} style={styles.commentBox}>
                    {!this.props.isNew &&
                        <Typography variant="caption">
                            <strong>username</strong> | {this.props.commentInfo.date}
                        </Typography>
                    }
                    <FormControl fullWidth={true}>
                        <Paper style={styles.textPaper} elevation={this.props.isNew ? 5 : 0}>
                            <InputBase
                                style={styles.text}
                                placeholder={this.props.isNew ? "Comment..." : this.props.commentInfo.comment}
                                value={this.props.commentInfo ? this.props.commentInfo.comment : this.state.comment}
                                disabled={this.props.isNew ? false : true} 
                                multiline
                                onChange={(event) => this.setState({ comment: (event.target.value) })}
                            />
                            {this.props.isNew &&
                                <Grid container style={styles.postTools.grid}>
                                    <Grid item xs={1} align="left">
                                        {this.props.isSubcomment &&
                                            <IconButton size="small" disableRipple disableFocusRipple style={styles.postTools.iconButton} onClick={() => this.props.showReplyCommentBox(this.props.pk, false)}>
                                                <CloseIcon fontSize="small" style={styles.postTools.icon} />
                                            </IconButton>
                                        }
                                    </Grid>
                                    <Grid item xs={11} align="right">
                                        <Button style={styles.postTools.button}>
                                            Post
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </Paper>
                    </FormControl>
                </Grid>
                {!this.props.isNew &&
                    <Grid container>
                        <Grid item xs={1}>
                            {/* spacing */}
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="caption">
                                {this.props.commentInfo.likes ? this.props.commentInfo.likes : "0"}
                                <IconButton size="small" disableRipple disableFocusRipple style={styles.firstIconButton} onClick={this.handleLike}>
                                    {this.state.liked
                                        ? <ThumbUpAltIcon fontSize="small" style={styles.icon} />
                                        : <ThumbUpAltOutlinedIcon fontSize="small" style={styles.icon} />
                                    }
                                </IconButton>
                                {!this.props.isSubcomment &&
                                    <IconButton size="small" disableRipple disableFocusRipple style={styles.iconButton} onClick={() => this.props.showReplyCommentBox(this.props.commentInfo.pk, true)}>
                                        <ReplyIcon fontSize="small" style={styles.icon} />
                                    </IconButton>
                                }
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={2} align="right">
                            <Typography variant="caption">
                                {this.props.commentInfo.date}
                            </Typography>
                        </Grid> */}
                    </Grid>
                }
            </Grid>
        )
    }
}

export default CommentBox