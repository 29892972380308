import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Timer from './Timer'
import Utils from './Utils';

const utils = new Utils();

class TopBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accountMenuOpen: false,
            searching: false,
            hoverSearch: false,
        }
        this.setSearching = this.setSearching.bind(this)
        this.setHoverSearch = this.setHoverSearch.bind(this)
    }

    setSearching(bool) {
        this.setState({ searching: bool })
    }

    setHoverSearch(bool) {
        this.setState({ hoverSearch: bool })
    }

    render() {
        const transitionDuration = 0.2
        const styles = {
            container: {
                height: "100%",
                paddingTop: 10,
            },
            firstItem: {
                height: "100%",
                padding: 5,
                paddingLeft: 20,
            },
            item: {
                height: "100%",
                padding: 5,
            },
            lastItem: {
                height: "100%",
            },
            iconButton: {
                // height: "80%",
                padding: 5,
                // marginRight: 15,
                color: this.props.colorScheme.two,
            },
            lastIconButton: {
                // height: "80%",
                padding: 5,
                marginRight: 20,
            },
            icon: {
                height: "100%",
                color: this.props.colorScheme.two,
            },
            searchBar: {
                root: {
                    display: 'flex',
                    alignItems: 'right',
                    width: this.state.searching ? "80%" : "60%",
                    marginTop: -2,
                    height: 40,
                    backgroundColor: "rgba(" + utils.hexToRgba(this.props.darkTheme ? (this.state.hoverSearch ? this.props.colorScheme.seven : this.props.colorScheme.eight) : (this.state.hoverSearch ? this.props.colorScheme.nine : this.props.colorScheme.eight), 0.5).join() + ")",
                    transition: `${"width " + transitionDuration + "s ease-in"}`,
                },
                input: {
                    marginLeft: 10,
                    marginRight: 10,
                    flex: 1,
                    padding: 5,
                    color: this.props.colorScheme.two,
                },
                iconButton: {
                    padding: 10,
                    backgroundColor: "transparent"
                },
                icon: {
                    padding: 5,
                    color: this.props.colorScheme.two,
                },
                divider: {
                    height: 32,
                    margin: 4,
                    backgroundColor: this.props.colorScheme.two,
                },
            },
            menu: {
                overflow: 'hidden', 
                position: "fixed",
                marginTop: "5%",
                minWidth: "30%",
                marginLeft: "70%",
            },
            heading: {
                height: "100%",
                padding: "2%",
                color: this.props.colorScheme.two,
            },
        }

        return (
            <Grid container style={styles.container}>
                <Grid item xs={6} align="left" style={styles.firstItem}>
                    <Grid container>
                        <Grid item xs={1}>
                            {/* <Tooltip title={this.props.showSideBar ? "Hide sidebar" : "Show sidebar"} enterDelay={3000}> */}
                                <IconButton
                                    style={styles.iconButton} 
                                    aria-label="color-mode-button" 
                                    onClick={() => this.props.toggleShowSideBar() }
                                >
                                    {this.props.showSideBar 
                                        ?   <ChevronLeftIcon fontSize="default" style={styles.icon} />
                                        :   <ChevronRightIcon fontSize="default" style={styles.icon} />
                                    }
                                </IconButton>
                            {/* </Tooltip> */}
                        </Grid>
                        <Grid item xs={5}>
                            <Timer 
                                colorScheme={this.props.colorScheme}
                                darkTheme={this.props.darkTheme}
                                openSnackbar={this.props.openSnackbar}
                                updateSnackbarMessage={this.props.updateSnackbarMessage}
                                closeSnackbar={this.props.closeSnackbar}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container align="right">
                        <Grid item xs={4}>
                            {/* Spacing */}
                        </Grid>
                        <Grid item xs={6} align="right" style={styles.item}>
                            <ClickAwayListener onClickAway={() => this.setSearching(false)}>
                                <Paper 
                                    elevation={0} 
                                    component="form" 
                                    style={styles.searchBar.root}
                                    onPointerEnter={() => this.setHoverSearch(true)}
                                    onPointerLeave={() => this.setHoverSearch(false)}
                                    onSelect={() => this.setSearching(true)}
                                >
                                    {/* <Divider style={styles.searchBar.divider} orientation="vertical" /> */}
                                    <InputBase
                                        style={styles.searchBar.input}
                                        placeholder="Search..."
                                        inputProps={{ 'aria-label': 'search' }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small" style={styles.searchBar.icon} />
                                            </InputAdornment>
                                        }
                                    />
                                </Paper>
                            </ClickAwayListener>
                        </Grid>
                        <Grid item xs={1} align="right" style={styles.item}>
                            <Tooltip title={this.props.darkTheme ? "Light theme" : "Dark theme"} enterDelay={1000}>
                                <IconButton
                                    style={styles.iconButton} 
                                    aria-label="color-mode-button" 
                                    onClick={() => this.props.toggleColorMode()}
                                >
                                    {this.props.darkTheme 
                                        ?   <BrightnessHighIcon fontSize="default" style={styles.icon} />
                                        :   <Brightness4Icon fontSize="default" style={styles.icon} />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1} align="right" style={styles.lastItem}>
                            <IconButton
                                aria-label="account-menu"
                                onClick={() => this.props.setLoginBody()}
                                // onClick={() => this.setState({ accountMenuOpen: !this.state.accountMenuOpen })}
                                style={styles.lastIconButton}
                            >
                                <AccountCircleIcon fontSize="large" style={styles.icon} />
                            </IconButton>
                            {/* <Menu
                                id="account-menu"
                                open={this.state.accountMenuOpen}
                                onClose={() => this.setState({ accountMenuOpen: !this.state.accountMenuOpen })}
                                style={styles.menu}
                            >
                                <Login colorScheme={this.props.colorScheme} />
                            </Menu> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default TopBar