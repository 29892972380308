import React, { Component } from 'react';
import {
    ThemeProvider,
    createMuiTheme,
  } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { green } from '@material-ui/core/colors';

import Button from '@material-ui/core/Button';

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            username: "",
            password: "",
            repassword: "",
            email: "",
            showPassword: false,
            registering: false,
        }
    }

    render() {
        const styles = {
            container: {
                height: "100%",
                paddingTop: "3%",
                paddingBottom: "3%",
                paddingLeft: "2%",
                paddingRight: "2%",
                backgroundColor: this.props.colorScheme.ten
            },
            paper: {
                padding: "5%",
                width: "100%",
                backgroundColor: this.props.colorScheme.ten
            },
            textPaper: {
                marginBottom: "5%",
                padding: "1%",
                width: "100%",
                backgroundColor: this.props.colorScheme.nine
            },
            formItem: {
                marginBottom: "5%"
            },
            text: {
                width: "100%",
                color: this.props.colorScheme.two,
                underline: {
                    color: this.props.colorScheme.two,
                }
            },
            inputLabel: {
                color: this.props.colorScheme.two
            },
            button: {
                marginTop: "5%",
                padding: "3%",
                color: this.props.colorScheme.ten,
                backgroundColor: this.props.colorScheme.two,
            },
            icon: {
                color: this.props.colorScheme.two,
            },
            item: {
                padding: 30,
            },
            divider: {
                backgroundColor: this.props.colorScheme.eight,
            }
        }

        const theme = createMuiTheme({
            overrides: {
                MuiInput: {
                    underline: {
                        borderBottom: `1px solid ${this.props.colorScheme.six}`,
                        "&:after": {  
                            borderBottom: `1px solid ${this.props.colorScheme.two}`
                        },
                        "&:hover": {  
                            borderBottom: `1px solid ${this.props.colorScheme.four}`
                        }
                    }
                }
            }
        });

        return (
            <Grid container style={styles.container}>
                <Grid item xs={12} style={styles.item}>
                    <Typography variant="h2" style={styles.text}>
                        ThePastPapers.com
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {/* space */}
                </Grid>
                <Grid item xs={6} style={styles.item}>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true} style={styles.formItem}>
                            <ThemeProvider theme={theme}>
                                <InputLabel htmlFor="username-field" style={styles.inputLabel}>Username</InputLabel>
                                <Input
                                    style={styles.text}
                                    value={this.state.username} 
                                    onChange={(event) => this.setState({ username: (event.target.value) })}
                                />
                            </ThemeProvider>
                        </FormControl>
                    </Grid>
                    {this.state.registering &&
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} style={styles.formItem}>
                                <ThemeProvider theme={theme}>
                                    <InputLabel htmlFor="email-field" style={styles.inputLabel}>Email address</InputLabel>
                                    <Input
                                        style={styles.text}
                                        value={this.state.email} 
                                        onChange={(event) => this.setState({ email: (event.target.value) })}
                                    />
                                </ThemeProvider>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl fullWidth={true} style={styles.formItem}>
                            <ThemeProvider theme={theme}>
                                <InputLabel htmlFor="password-field" style={styles.inputLabel}>Password</InputLabel>
                                <Input
                                    style={styles.text}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password} 
                                    onChange={(event) => this.setState({ password: (event.target.value) })} 
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                            // onMouseDown={(event) => this.preventDefault(event)}
                                        >
                                            {this.state.showPassword ? <Visibility style={styles.icon} /> : <VisibilityOff style={styles.icon} />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </ThemeProvider>
                        </FormControl>
                    </Grid>
                    {this.state.registering &&
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} style={styles.formItem}>
                                <ThemeProvider theme={theme}>
                                    <InputLabel htmlFor="repassword-field" style={styles.inputLabel}>Re-enter password</InputLabel>
                                    <Input
                                        style={styles.text}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={this.state.repassword} 
                                        onChange={(event) => this.setState({ repassword: (event.target.value) })} 
                                    />
                                </ThemeProvider>
                            </FormControl>
                        </Grid>
                    }
                    {this.state.registering
                        ?   <Grid container>
                                <Grid item xs={6} align="left">
                                    <Button style={styles.button}>
                                        Sign up
                                    </Button>
                                </Grid>
                                <Grid item xs={6} align="right">
                                    <Button style={styles.button} onClick={(event) => this.setState({ registering: false })}>
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        :   <Grid container>
                                <Grid item xs={6} align="left">
                                    <Button style={styles.button}>
                                        Login
                                    </Button>
                                </Grid>
                                <Grid item xs={6} align="right">
                                    <Button style={styles.button} onClick={(event) => this.setState({ registering: true })}>
                                        New user
                                    </Button>
                                </Grid>
                            </Grid>
                    }
                </Grid>
                <Grid item xs={3}>
                    {/* space */}
                </Grid>
                <Grid item xs={12} style={styles.item}>
                    <Typography variant="p2" style={styles.text}>
                        You don't need to create an account to use this webapp. However, please sign in/up if you would like to use all the webapp's features
                    </Typography>
                </Grid>
                {/* <Grid align="center" item xs={2}>
                    <Divider style={styles.divider} orientation="vertical" />
                </Grid>
                <Grid item xs={5} style={styles.item}>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <Paper style={styles.textPaper}>
                                <InputBase
                                    style={styles.text}
                                    placeholder="Email"
                                    value={this.state.email} 
                                    onChange={(event) => this.setState({ email: (event.target.value) })} 
                                />
                            </Paper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <Paper style={styles.textPaper}>
                                <InputBase
                                    style={styles.text}
                                    placeholder="Username"
                                    value={this.state.username} 
                                    onChange={(event) => this.setState({ username: (event.target.value) })} 
                                />
                            </Paper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <Paper style={styles.textPaper}>
                                <InputBase
                                    style={styles.text}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    value={this.state.password} 
                                    onChange={(event) => this.setState({ password: (event.target.value) })} 
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                            // onMouseDown={(event) => this.preventDefault(event)}
                                        >
                                            {this.state.showPassword ? <Visibility style={styles.icon} /> : <VisibilityOff style={styles.icon} />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </Paper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <Paper style={styles.textPaper}>
                                <InputBase
                                    style={styles.text}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    placeholder="Re-enter password"
                                    value={this.state.repassword} 
                                    onChange={(event) => this.setState({ repassword: (event.target.value) })}
                                />
                            </Paper>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button style={styles.button}>
                            Create Account
                        </Button>
                    </Grid>
                </Grid> */}
            </Grid>
        )
    }
}

export default Login