import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { colorSchemes } from './Styles'

import SideBar from './SideBar';
import TopBar from './TopBar';
import Body from './Body';
import Login from './Login';
import { sampleData } from './SampleData';
import { shadows } from '@material-ui/system';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Utils from './Utils';

const utils = new Utils();

function Alert(props) {
    return(
        <MuiAlert elevation={6} variant="filled" {...props} />
    );
}

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            showPassword: false,
            colorSchemes: colorSchemes.default,
            colorScheme: colorSchemes.default.light,
            darkTheme: false,
            contentBody: false,
            loginBody: true,
            showSideBar: true,
            selectedPaper: {
                yearGroup: "",
                module: "",
                paper: "",
                rating: null,
                paper_url: "",
                solutions_url: "",
                completed: null,
                path: [],
            },
            data: sampleData.papers,

            isnackbarOpen: false,
            snackbarMessage: "",
            snackbarDuration: null,
            snackbarSeverity: "success",
        }
        this.toggleColorMode = this.toggleColorMode.bind(this)
        this.setLoginBody = this.setLoginBody.bind(this)
        this.setContentBody = this.setContentBody.bind(this)
        this.toggleShowSideBar = this.toggleShowSideBar.bind(this)
        this.toggleExpand = this.toggleExpand.bind(this)
        this.selectPaper = this.selectPaper.bind(this)
        this.toggleCompleted = this.toggleCompleted.bind(this)
        this.toggleFlagged = this.toggleFlagged.bind(this)
        this.addUserRating = this.addUserRating.bind(this)
        this.openSnackbar = this.openSnackbar.bind(this)
        this.updateSnackbarMessage = this.updateSnackbarMessage.bind(this)
        this.closeSnackbar = this.closeSnackbar.bind(this)
    }

    toggleColorMode() {
        if (!this.state.darkTheme) {
            this.setState({ colorScheme: this.state.colorSchemes.dark, darkTheme: true })
        } else {
            this.setState({ colorScheme: this.state.colorSchemes.light, darkTheme: false })
        }
    }

    setLoginBody() {
        this.setState({ loginBody: true, contentBody: false })
    }

    setContentBody() {
        this.setState({ loginBody: false, contentBody: true })
    }

    toggleShowSideBar() {
        this.setState({ showSideBar: !this.state.showSideBar })
    }

    toggleExpand(path) {
        const newData= this.state.data
        path = path.split("/")
        if (path.length === 1) {
            newData[path[0]].expanded = !this.state.data[path[0]].expanded
        } else {
            newData[path[0]].modules[path[1]].expanded = !this.state.data[path[0]].modules[path[1]].expanded
        }
        this.setState({ data: newData })
    }

    selectPaper(path) {
        var path = path.split("/")
        var paper = {
            yearGroup: this.state.data[path[0]].name,
            module: this.state.data[path[0]].modules[path[1]].name,
            paper: this.state.data[path[0]].modules[path[1]].papers[path[2]].name,
            rating: this.state.data[path[0]].modules[path[1]].papers[path[2]].rating,
            userRating: this.state.data[path[0]].modules[path[1]].papers[path[2]].userRating,
            paper_url: this.state.data[path[0]].modules[path[1]].papers[path[2]].paper_url,
            solutions_url: this.state.data[path[0]].modules[path[1]].papers[path[2]].solutions_url,
            completed: this.state.data[path[0]].modules[path[1]].papers[path[2]].completed,
            flagged: this.state.data[path[0]].modules[path[1]].papers[path[2]].flagged,
            path: path,
        }
        paper.name = paper.module + " " + paper.paper
        this.setState({ selectedPaper: paper, loginBody: false, contentBody: true })
    }

    toggleCompleted() {
        var paper = this.state.selectedPaper
        paper.completed = !this.state.selectedPaper.completed
        var data = this.state.data
        data[paper.path[0]].modules[paper.path[1]].papers[paper.path[2]].completed = paper.completed
        this.setState({ selectedPaper: paper, data: data })
    }

    toggleFlagged() {
        var paper = this.state.selectedPaper
        paper.flagged = !this.state.selectedPaper.flagged
        var data = this.state.data
        data[paper.path[0]].modules[paper.path[1]].papers[paper.path[2]].flagged = paper.flagged
        this.setState({ selectedPaper: paper, data: data })
    }

    addUserRating(rating) {
        var paper = this.state.selectedPaper
        paper.userRating = rating
        var data = this.state.data
        data[paper.path[0]].modules[paper.path[1]].papers[paper.path[2]].userRating = paper.userRating
        this.setState({ selectedPaper: paper, data: data })
        this.openSnackbar("Paper rated!", "success", 1000)
    }

    openSnackbar(message, type, duration) {
        this.setState({ snackbarOpen: true, snackbarMessage: message, snackbarDuration: duration, snackbarSeverity: type })
    }

    updateSnackbarMessage(message, type) {
        if (type == this.state.snackbarSeverity) {
            this.setState({ snackbarMessage: message })
        }
    }

    closeSnackbar(type) {
        if (type == this.state.snackbarSeverity) {
            this.setState({ snackbarOpen: false })
        }
    }

    render() {
        const transitionDuration = 0.2
        const styles = {
            container: {
                height: "100%",
                backgroundColor: this.state.colorScheme.one,
            },
            sideBar: {
                height: "100%",
                backgroundColor: this.state.colorScheme.ten,
                overflow: "auto",
                width: this.state.showSideBar ? 240 : "0%",
                transition: `${"width " + transitionDuration + "s ease-in"}`,
            },
            main: {
                height: "100%",
                backgroundColor: this.state.colorScheme.ten,
                width: this.state.showSideBar ? "calc(100% - 240px)" : "100%",
                transition: `${"width " + transitionDuration + "s ease-in"}`,
            },
            body: {
                height: "calc(100% - 65px)",
                backgroundColor: this.state.colorScheme.ten,
                boxShadow: "5",
                borderLeft: "solid",
                borderWidth: this.state.showSideBar ? 1 : 0,
                borderColor: this.state.darkTheme ? "rgba(" + utils.hexToRgba(this.state.colorScheme.eight, 0.5).join() + ")" : this.state.colorScheme.seven,
                overflow: "auto",
                transition: `${"border-width " + transitionDuration + "s ease-in"}`,
            },
            bodyBox: {
                height: "100%",
                color: this.state.colorScheme.two
            },
            topBar: {
                height: 65,
                backgroundColor: this.state.darkTheme ? "rgba(" + utils.hexToRgba(this.state.colorScheme.nine, 0.5).join() + ")" : this.state.colorScheme.seven,
            },
            topBarBox: {
                height: "100%",
            }
        }

        return (
            <Grid container style={styles.container}>
                <Grid container style={styles.sideBar}>
                    <SideBar 
                        colorScheme={this.state.colorScheme} 
                        darkTheme={this.state.darkTheme} 
                        data={this.state.data} width={styles.sideBar.width} 
                        toggleExpand={this.toggleExpand} 
                        selectPaper={this.selectPaper} 
                        openSnackbar={this.openSnackbar}
                        updateSnackbarMessage={this.updateSnackbarMessage}
                        closeSnackbar={this.closeSnackbar}
                    />
                </Grid>
                <Grid container style={styles.main}>
                    <Grid item xs={12} style={styles.topBar}>
                        <Box boxShadow={3} style={styles.topBarBox}>
                            <TopBar 
                                colorScheme={this.state.colorScheme} 
                                darkTheme={this.state.darkTheme} 
                                toggleColorMode={this.toggleColorMode} 
                                setLoginBody={this.setLoginBody} 
                                showSideBar={this.state.showSideBar} 
                                toggleShowSideBar={this.toggleShowSideBar}
                                openSnackbar={this.openSnackbar}
                                updateSnackbarMessage={this.updateSnackbarMessage}
                                closeSnackbar={this.closeSnackbar}
                            />
                        </Box>
                    </Grid>
                    <Grid id="body" item xs={12} style={styles.body}>
                        <Box boxShadow={0} style={styles.topBarBox}>
                            {this.state.loginBody
                                ?   <Login colorScheme={this.state.colorScheme} darkTheme={this.state.darkTheme} openSnackbar={this.openSnackbar} />
                                :   <Body 
                                        colorScheme={this.state.colorScheme} 
                                        darkTheme={this.state.darkTheme} 
                                        selectedPaper={this.state.selectedPaper}
                                        addUserRating={this.addUserRating}
                                        toggleCompleted={this.toggleCompleted} 
                                        toggleFlagged={this.toggleFlagged} 
                                        openSnackbar={this.openSnackbar}
                                        updateSnackbarMessage={this.updateSnackbarMessage}
                                        closeSnackbar={this.closeSnackbar}
                                    />
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={this.state.snackbarOpen} autoHideDuration={this.state.snackbarDuration} onClose={() => this.setState({ snackbarOpen: false })}>
                    <Alert onClose={() => this.setState({ snackbarOpen: false })} severity={this.state.snackbarSeverity}>
                        {this.state.snackbarMessage}
                    </Alert>
                </Snackbar>
            </Grid>
        )
    }
}

export default Dashboard