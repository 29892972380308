const sampleData = {
    papers: [
        {
            name: "EE1",
            expanded: true,
            modules: [
                {
                    name: "Analysis of Circuits",
                    code: "EE1-01",
                    expanded: true,
                    papers: [
                        {
                            name: "Summer 2001",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2001.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2002",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2002.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2003",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2003.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2004",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2004.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2005",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2005.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2006",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2006.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2007",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2007.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2008",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2008.pdf",
                            solutions_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2008_sols.pdf",
                            completed: true,
                            flagged: false,
                        },
                        {
                            name: "Summer 2009",
                            rating: 4,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2009.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2010",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2010.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2011",
                            rating: 0,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2011.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2012",
                            rating: 1,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2012.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2013",
                            rating: 5,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2013.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2014",
                            rating: 2,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2014.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2015",
                            rating: 4,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2015.pdf",
                            solutions_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/20015_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2016",
                            rating: 5,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2016.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2017",
                            rating: 4,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2017.pdf",
                            solutions_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2017_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2018",
                            rating: 1,
                            userRating: 0,
                            paper_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2018.pdf",
                            solutions_url: "https://storage.cloud.google.com/eee-past-papers/EE1/01-Analysis-of-Circuits/2018_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                    ],
                },
                {
                    name: "Digital Electronics",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Analogue Devices",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Energy Conversion",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Signal and Communications",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Software Engineering",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Computer Architecture",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Operating Systems",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Mathematics A",
                    expanded: false,
                    papers: [],
                },
                {
                    name: "Mathematics B", 
                    expanded: false,
                    papers: [],
                },
            ],
        },
        {
            name: "EE2",
            expanded: false,
            modules: [],
        },
        {
            name: "EE3",
            expanded: false,
            modules: [],
        },
        {
            name: "EE4",
            expanded: false,
            modules: [
                {
                    name: "Optimisation",
                    code: "EE4-29",
                    expanded: false,
                    papers: [
                        {
                            name: "Summer 2001",
                            rating: 5,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2001.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2002",
                            rating: 4,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2002.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2003",
                            rating: 3,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2003.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2003_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2004",
                            rating: 1,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2004.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2005",
                            rating: 5,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2005.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2006",
                            rating: 2,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2006.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2007",
                            rating: 4,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2007.pdf",
                            solutions_url: "",
                            flagged: false,
                        },
                        {
                            name: "Summer 2008",
                            rating: 5,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2008.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2008_sols.pdf",
                            completed: true,
                            flagged: false,
                        },
                        {
                            name: "Summer 2009",
                            rating: 4,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2009.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2010",
                            rating: 5,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2010.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2011",
                            rating: 3,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2011.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2012",
                            rating: 1,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2012.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2013",
                            rating: 5,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2013.pdf",
                            solutions_url: "",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2014",
                            rating: 2,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2014.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2014_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2015",
                            rating: 4,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2015.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2015_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2016",
                            rating: 5,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2016.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2016_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2017",
                            rating: 4,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2017.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2017_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                        {
                            name: "Summer 2018",
                            rating: 1,
                            userRating: 0,
                            paper_url: "http://www.padkay.co.uk/EE4/EE4-29/2018.pdf",
                            solutions_url: "http://www.padkay.co.uk/EE4/EE4-29/2018_sols.pdf",
                            completed: false,
                            flagged: false,
                        },
                    ],
                },
            ],
        },
    ],
}

export { sampleData }